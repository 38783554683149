<script setup lang="ts">
import type { LanguageCodeFilterEnum } from "#gql/default";
import type { FormError } from "#ui/types";
import IconCall from "~/components/Icon/Call.vue";

const props = defineProps<{
  mode: "horizontal" | "vertical";
  /**
   * If true, the heading will not be displayed. E-book detail page has it.
   */
  noHeading?: boolean;
  cta?: string;
  redirect?: string;
  uppercaseTitle?: boolean;
  narrow?: boolean;
}>();

const { t, locale } = useI18n();
const toast = useToast();

const gql = useGql();
const { data } = await useLazyAsyncData(
  "form-visa-options",
  async () => {
    const servicesOptions = await gql({
      operation: "GetContactFormServicesOptions",
      variables: {
        language: locale.value.toUpperCase() as LanguageCodeFilterEnum,
      },
    });

    const slugPrefix = `visa-category-${locale.value}-`;

    return {
      options: servicesOptions.visaCategories?.nodes?.map(node => ({
        label: node?.name || "",
        value: node?.slug?.replace(slugPrefix, "") || "",
      })) ?? [],
      locale: locale.value,
    };
  },
  {
    server: false,
    watch: [locale],
    getCachedData(key, nuxtApp) {
      const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key];
      if (data?.locale === locale.value)
        return nuxtApp.payload.data[key] || nuxtApp.static.data[key];
      else
        return null;
    },
  },
);

const formData = ref(getDefaultFormData());
function getDefaultFormData() {
  const form: {
    name: string;
    email: string;
    phone: string;
    hasTouristVisa: string;
    visa: { label: string; value: string; } | undefined;
    americaPlans: string;
    investmentBudget: string;
    employeeCount: number | undefined;
    profession: string;
    educationLevel: string;
    captchaToken: string;
  } = {
    name: "",
    email: "",
    phone: "",
    hasTouristVisa: "",
    visa: undefined,
    americaPlans: "",
    investmentBudget: "",
    employeeCount: undefined,
    profession: "",
    educationLevel: "",
    captchaToken: "",
  };

  return form;
}

const visaOptions = ref<Array<{ label: string; value: string; }>>([]);
watch(data, () => {
  if (data.value) {
    visaOptions.value = [{ label: t("formVisaInputDontKnow"), value: t("formVisaInputDontKnow") }, ...data.value.options];

    const slug = useRoute().params.slug as string;
    formData.value.visa = visaOptions.value.find(option => option.value === slug);
  }
});

const visaCategory = computed<"investor" | "intracompany" | "student" | "professional" | null>(() => {
  if (!formData.value.visa?.value)
    return null;

  const slug = formData.value.visa.value.toLowerCase();

  if (slug.includes("e-1") || slug.includes("e-2")) {
    return "investor";
  }
  else if (slug.includes("l-1")) {
    return "intracompany";
  }
  else if (slug.includes("m-1") || slug.includes("f-1")) {
    return "student";
  }
  else if (slug.includes("eb-") || slug.includes("o-") || slug.includes("p-")
    || slug.includes("e-3") || slug.includes("h-") || (slug.includes("i-") && !slug.includes("k-1")) || slug.includes("j-1")
    || slug.includes("r-1")) {
    return "professional";
  }
  else {
    return null;
  }
});

async function validate(state: typeof formData.value) {
  const errors: FormError[] = [];

  if (!state.name || state.name.trim().length < 3)
    errors.push({ path: "name", message: t("formNameWarning") });

  const emailRegex = /^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]+$/;
  if (!state.email) {
    errors.push({ path: "email", message: t("formEmailWarning") });
  }
  else if (!emailRegex.test(state.email)) {
    errors.push({ path: "email", message: t("formValidEmailWarning") });
  }

  if (!state.hasTouristVisa) {
    errors.push({ path: "hasTouristVisa", message: t("formHasTouristVisaWarning") });
  }

  if (!state.visa)
    errors.push({ path: "visa", message: t("formVisaWarning") });

  const phoneRegex = /^[0-9\s+-]*$/;
  if (!state.phone)
    errors.push({ path: "phone", message: t("formPhoneWarning") });
  else if (!phoneRegex.test(state.phone))
    errors.push({ path: "phone", message: t("formValidPhoneWarning") });

  if (visaCategory.value === "investor" || visaCategory.value === "intracompany") {
    if (!state.investmentBudget)
      errors.push({ path: "investmentBudget", message: t("formInvestmentBudgetWarning") });
  }

  if (visaCategory.value === "intracompany") {
    if (!state.employeeCount)
      errors.push({ path: "employeeCount", message: t("formEmployeeCountWarning") });
  }

  if (visaCategory.value === "student" || visaCategory.value === "professional") {
    if (!state.educationLevel)
      errors.push({ path: "educationLevel", message: t("formEducationLevelWarning") });
  }

  if (visaCategory.value === "professional") {
    if (!state.profession)
      errors.push({ path: "profession", message: t("formProfessionWarning") });
  }

  if (!state.captchaToken) {
    errors.push({ path: "captchaToken", message: t("formCatchaTokenWarning") });
  }
  else {
    const response = await $fetch("/_turnstile/validate", {
      method: "POST",
      body: {
        token: state.captchaToken,
      },
    });

    if (!response.success)
      errors.push({ path: "captchaToken", message: t("formCatchaTokenWarning") });
  }

  return errors;
}

// Form submission
const pending = ref(false);
const showSuccessModal = ref(false);

async function handleSubmit() {
  pending.value = true;

  try {
    await $fetch("/api/hubspot/form", {
      method: "post",
      body: {
        name: formData.value.name,
        email: formData.value.email,
        phone: formData.value.phone,
        hasTouristVisa: formData.value.hasTouristVisa === t("formTouristVisaYes"),
        visa: formData.value.visa?.label ?? "Unknown",
        americaPlans: formData.value.americaPlans,
        investmentBudget: formData.value.investmentBudget,
        employeeCount: formData.value.employeeCount ?? -1,
        profession: formData.value.profession,
        educationLevel: formData.value.educationLevel,
        url: window.location.href,
      }, // TODO: Should satisfy HubspotFormReqBody
    });
    showSuccessModal.value = true;
    formData.value = getDefaultFormData();
    if (props.redirect) {
      window.open(props.redirect, "_blank");
    }
  }
  catch (error) {
    toast.add({ title: t("formContactSubmitErrorTitle"), description: t("formContactSubmitErrorDescription"), color: "red" });
  }

  pending.value = false;
}
</script>

<template>
  <div
    class="space-y-8"
    :class="{
      'mx-auto max-w-4xl': narrow,
    }"
  >
    <div
      v-if="!noHeading"
      class="space-y-2"
    >
      <p
        class="text-xl font-semibold text-red-500 sm:text-2xl"
        :class="mode === 'horizontal' ? 'text-left' : 'sm:text-center'"
      >
        {{ uppercaseTitle ? t("formContactTitle").toLocaleUpperCase(locale) : t("formContactTitle") }}
      </p>

      <p
        class="text-base font-medium text-text-title sm:text-xl"
        :class="mode === 'horizontal' ? 'text-left' : 'sm:text-center'"
      >
        {{ t("formContactDescription") }}
      </p>
    </div>

    <UForm
      class="grid w-full gap-4"
      :class="{
        'lg:grid-cols-2': mode === 'horizontal',
      }"
      :state="formData"
      :validate="validate"
      @submit="handleSubmit"
    >
      <UFormGroup
        name="name"
        :label="t('formNameInput')"
      >
        <UInput
          v-model="formData.name"
          size="xl"
        />
      </UFormGroup>

      <UFormGroup
        name="email"
        :label="t('formEmailInput')"
      >
        <UInput
          v-model="formData.email"
          size="xl"
          type="email"
        />
      </UFormGroup>

      <UFormGroup
        name="phone"
        :label="t('formPhoneInput')"
      >
        <UInput
          v-model="formData.phone"
          :placeholder="t('formPhoneInputPlaceholder')"
          size="xl"
          type="tel"
        />
      </UFormGroup>

      <UFormGroup
        name="hasTouristVisa"
        :label="t('formTouristVisaInput')"
      >
        <USelectMenu
          v-model="formData.hasTouristVisa"
          :options="[t('formTouristVisaYes'), t('formTouristVisaNo')]"
          size="xl"
        />
      </UFormGroup>

      <UFormGroup
        name="visa"
        :label="t('formVisaInput')"
        :class="{
          'lg:col-span-2': mode === 'horizontal',
        }"
      >
        <USelectMenu
          v-model="formData.visa"
          :options="visaOptions"
          size="xl"
          searchable
          :searchable-placeholder="t('formVisaInputSearchPlaceholder')"
        />
      </UFormGroup>

      <UFormGroup
        v-if="visaCategory === 'investor' || visaCategory === 'intracompany'"
        name="investmentBudget"
        :label="t('formInvestmentBudgetInput')"
        :class="{
          'lg:col-span-2': mode === 'horizontal' && visaCategory === 'investor',
        }"
      >
        <UInput
          v-model="formData.investmentBudget"
          placeholder="$60,000"
          size="xl"
          type="number"
        />
      </UFormGroup>

      <UFormGroup
        v-if="visaCategory === 'intracompany'"
        name="employeeCount"
        :label="t('formEmployeeCountInput')"
      >
        <UInput
          v-model="formData.employeeCount"
          size="xl"
          type="number"
        />
      </UFormGroup>

      <UFormGroup
        v-if="visaCategory === 'professional'"
        name="profession"
        :label="t('formProfessionInput')"
      >
        <UInput
          v-model="formData.profession"
          size="xl"
        />
      </UFormGroup>

      <UFormGroup
        v-if="visaCategory === 'student' || visaCategory === 'professional'"
        name="educationLevel"
        :label="t('formEducationLevelInput')"
        :class="{
          'lg:col-span-2': mode === 'horizontal' && visaCategory === 'student',
        }"
      >
        <UInput
          v-model="formData.educationLevel"
          size="xl"
        />
      </UFormGroup>

      <UFormGroup
        :class="{
          'lg:col-span-2': mode === 'horizontal',
        }"
        name="americaPlans"
        :label="t('formAmericanPlansInput')"
      >
        <UTextarea
          v-model="formData.americaPlans"
          size="xl"
          :rows="mode === 'horizontal' ? 8 : 3"
          :maxrows="8"
          autoresize
        />
      </UFormGroup>

      <UFormGroup
        name="captchaToken"
        :class="{
          'lg:col-span-2': mode === 'horizontal',
        }"
      >
        <Captcha v-model="formData.captchaToken" />
      </UFormGroup>

      <UButton
        type="submit"
        size="xl"
        color="red"
        :label="cta || t('formCtaButton')"
        class="flex items-center justify-center font-semibold"
        :class="{
          'w-full': mode === 'vertical',
          'lg:w-fit': mode === 'horizontal',
        }"
        :loading="pending"
      />
    </UForm>

    <!-- Success Modal -->
    <UModal
      v-model="showSuccessModal"
      :ui="{
        width: 'sm:max-w-sm',
      }"
    >
      <UCard>
        <FormSuccess
          v-model="showSuccessModal"
          :icon="IconCall"
          :title="t('formSuccessModalTitle')"
          :description="t('formSuccessModalDescription')"
          @close="showSuccessModal = false"
        />
      </UCard>
    </UModal>
  </div>
</template>
